<template>
  <div class="tojoy-dialog">
    <el-dialog
      :title="processTemplateName || '默认流程'"
      :visible.sync="showTip"
      width="560px"
      :before-close="handleBeforeClose"
      v-if="showTip"
    >
      <section class="tojoy-dialog__body">
        <div v-if="index == 1" style="width: 90%; margin-left: 5%">
          <tojoy-technological :leveNodeData="nodeData">
            <template #exec="{ data }">
              <template v-if="data.isShow">
                <div class="sele-box" v-if="data.node.userName">
                  {{ data.node.userName }}<i class="el-icon-error" @click="deletePerson(data)" />
                </div>
                <choose-people
                  v-else
                  :show="false"
                  v-model="data.node"
                  :disabled="false"
                  :multiple="false"
                  :remote="true"
                  :isItemObj="true"
                  placeholder="请填写"
                ></choose-people>
              </template>
            </template>
          </tojoy-technological>
        </div>
      </section>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleConfirm">确认提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import TojoyTechnological from '@/components/common/technological/approval-process'
import ChoosePeople from '@/components/common/tojoy-select/person-selected'
import { getInnovationProcessList } from '@/api/api_innovation'
import { getInnovationProcessList as getTeamInnovationProcessList } from '@/api/api_team'
import { getCustomerProcessList } from '@/api/api_customer'
export default {
  name: 'tojoy-eval-selected',
  components: {
    TojoyTechnological,
    ChoosePeople
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    innovateLevel: {
      type: Number,
      default: 0
    },
    innovateLevelName: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    tDialog: {
      type: Object,
      default: () => {
        return {
          title: '加载审批流名称'
        }
      }
    },
    display: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      //loading: false,
      showTip: this.display,
      processTemplateId: 0,
      processTemplateName: '',
      nodeData: [],
      index: 1
    }
  },
  methods: {
    deletePerson(data) {
      Object.keys(data.node).forEach(key => {
        if (key !== 'userId' && key !== 'userName') {
          delete data.node[key];
        }
      });
      this.$set(data.node, 'userName', '')
      this.$set(data.node, 'userId', '')
    },
    async handleNodeApi(params) {
      try {
        if (this.$parent.modelCode === 'team') {
          if (sessionStorage.getItem('code') === 'contribute') {
            //团队贡献
            return await getTeamInnovationProcessList(params)
          } else {
            //自定义维度
            return await getCustomerProcessList({ dimensionCode: sessionStorage.getItem('code') })
          }
        } else {
          return await getInnovationProcessList(params)
        }
      } catch (err) {
        return err
      }
    },
    getData(processNodeOutputList, processTemplateId, processTemplateName) {
      this.processTemplateName = processTemplateName
      this.processTemplateId = processTemplateId
      processNodeOutputList.forEach((el, index) => {
        let obj = {
          effectScope: this.innovateLevel,
          nodeId: el.nodeId,
          nodeName: el.nodeName,
          isShow: true,
          node: {
            userId: el.approveId || '',
            userName: el.approveName || ''
          },
          roleId: el.roleId,
          roleName: el.roleName,
          isEntrust: el.isEntrust,
          processTemplateId: el.processTemplateId,
          processTemplateName: this.processTemplateName
        }
        this.nodeData.push(obj)
      })
    },
    handleNode() {
      this.handleNodeApi({
        effectId: this.innovateLevel,
        effectScope: this.innovateLevelName,
        implementOrgId: this.id
      })
        .then(res => {
          let { processNodeOutputList, processTemplateId, processTemplateName } = res.data[0]
          this.getData(processNodeOutputList, processTemplateId, processTemplateName)
        })
        .catch(() => {})
    },
    handleConfirm() {
      if (this.nodeData.length < 2) return false
      let processExampleDtoList = []
      let rs = this.nodeData.some((el, index) => {
        if (index > 0) {
          return !el.node.userId
        } else {
          return false
        }
      })
      if (rs) {
        this.$msgError('请先添加审批人')
        return false
      }
      this.nodeData.shift()
      this.nodeData.forEach(el => {
        let {
          nodeId,
          nodeName,
          node,
          node: { userId, userName },
          roleId,
          roleName,
          isEntrust,
          processTemplateId,
          processTemplateName,
          effectScope
        } = el
        let obj = {
          nodeId,
          nodeName,
          roleId,
          roleName,
          approveId: userId,
          approveName: userName,
          isEntrust,
          processTemplateId,
          processTemplateName,
          effectScope
        }
        processExampleDtoList.push(obj)
      })
      this.$emit(
        'handleConfirm',
        processExampleDtoList,
        this.processTemplateId,
        this.processTemplateName
      )
    },
    handleBeforeClose(done) {
      done()
      this.nodeData = []
      this.showTip = false
      this.$parent.showNodeDialog = false
    },
    changeIndex(index) {
      this.index = index
    }
  },
  watch: {
    display(val) {
      this.showTip = this.display
      if (this.showTip) {
        this.nodeData = []
        this.processTemplateId = 0
        this.processTemplateName = ''
        this.nodeData = [
          {
            nodeId: 1,
            nodeName: '发起提案'
          }
        ]
        this.handleNode()
        // if (this.$parent.type === 'add') {
        //   this.handleNode()
        // } else {
        //   if (this.$parent.modelCode === 'team') {
        //     let {
        //       processNodeOutputList,
        //       processTemplateId,
        //       processTemplateName
        //     } = this.$parent.processObj
        //     this.getData(processNodeOutputList, processTemplateId, processTemplateName)
        //   } else {
        //     this.handleNode()
        //   }
        // }
      }
    }
  }
}
</script>
<style lang="scss">
.#{$css-prefix}-dialog {
  /deep/.el-dialog__body {
    border-top: 1px solid #f0f0f0;
  }
  /deep/.el-dialog__header {
    padding-bottom: 20px;
  }
  /deep/.el-dialog--center .el-dialog__body {
    padding: 20px 30px 30px 30px;
  }
  &__header {
    display: flex;
    z-index: 10;
    position: relative;
    .tabWrap {
      width: 100%;
      height: 110px;
      padding: 0 5%;
      box-sizing: border-box;
      display: flex;
      margin-top: 15px;
      background: #ffffff;
      box-shadow: 0 7px 10px 6b6b6b0a;
      align-items: center;
      justify-content: center;
      margin-top: -5px;
      .tabItem {
        flex: 1;
        height: 70px;
        cursor: pointer;
        border: 1px solid $linecolor;
        border-radius: 3px;
        padding: 15px 20px;
        box-sizing: border-box;
        position: relative;
      }

      .spanCheck {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .active {
        height: 70px;
        border: 1px solid $blue;
        position: relative;
        transition: all 0.5s ease;
      }
      .active:after {
        content: '✔';
        display: block;
        height: 0px;
        width: 0px;
        position: absolute;
        bottom: 0;
        right: 0;
        color: $white;
        /**对号大小*/
        font-size: 10px;
        line-height: 8px;
        border: 10px solid;
        border-color: transparent $blue $blue transparent;
      }
      .tabItem .title {
        font-size: 16px;
        color: $lightblack;
        font-weight: 500;
      }

      .tabItem .fu {
        font-size: 12px;
        color: $grey;
        line-height: 28px;
      }
    }
  }

  &__body {
    margin-top: 30px;
    max-height: 400px;
    overflow-y: auto;
    .el-icon-info {
      font-size: 14px;
      color: $blue;
    }
    .h3 {
      font-size: 18px;
      font-weight: 500;
      color: $lightblack;
      margin: 30px 0;
    }
    .mb20 {
      margin-bottom: 20px;
    }
  }

  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__header {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: $lightblack;
    line-height: 18px;
  }
  .el-dialog__footer {
    z-index: 10;
    height: 64px;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 16px 0;
    position: relative;
  }
  .el-button--primary {
    color: $white;
    width: 100px;
    height: 32px;
    background: $blue;
    border-radius: 3px;
    line-height: 32px;
  }
  .el-button {
    padding: 0;
  }
  .sele-box {
    border-radius: 17px;
    border: 1px solid $grey600;
    font-size: 12px;
    font-weight: 400;
    color: $lightblack;
    line-height: 24px;
    padding: 0 8px 0 12px;
    display: inline-block;
    margin-top: 15px;
    i {
      cursor: pointer;
      width: 13px;
      height: 13px;
      color: $grey600;
      margin-left: 5px;
    }
    &:hover {
      color: $blue;
      border-color: $blue;
      cursor: pointer;
    }
  }
}
</style>
